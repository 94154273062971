import { useEffect, useState } from "react";
import preloader from "./assets/img/preloader.png";

export const Preloader = () => {
  const [animationFinishedIndicator, setAnimationFinishedIndicator] = useState(false);
  const [number, setNumber] = useState(0);

  const initAnimation = () => {
    setTimeout(() => {
      setAnimationFinishedIndicator(true);
      setTimeout(() => {
        setNumber(100);
      }, 100);
    }, 3400);

    const loadInterval = setInterval(() => {
      if (number < 100)
        setNumber(prev => prev++);
    }, 50);

    const interval = setInterval(() => {
      if (document.readyState === 'complete') {
        setNumber(100)
        clearInterval(interval);
        clearInterval(loadInterval);
        setAnimationFinishedIndicator(true);
      }
    }, 100);
  }

  useEffect(() => {
    initAnimation();
  }, []);

  return (
    <div className={'preloader ' + (animationFinishedIndicator ? ('animation') : (''))}>
      <div className="preloader__box">
        <div className="preloader-image-container">
          <img src={preloader} alt="" className="preloader__box__img" />
        </div>
        <span className="number">{number}%</span>
      </div>
    </div>
  )
}
