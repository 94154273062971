import './css/style.css';
import dog from './assets/img/dog.png';
import background from './assets/img/background.png';
import { Preloader } from './Preloader';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    if (window.innerWidth > 850) {

      let bg = document.querySelector('.app__background');

      window.addEventListener('mousemove', function (e) {
        let x = e.clientX / window.innerWidth;
        let y = e.clientY / window.innerHeight;
        bg.style.transform = 'translate(-' + x * 30 + 'px, -' + y * 30 + 'px) scale(1.1)';
      });
    }
  }, []);

  return (
    <div className="app">
      <header className='app__header'>
        <a href='https://t.me/OmikamiPortal' className='app__header__name'>OMIKAMI</a>
        <a href='https://t.me/OmikamiPortal' className='app__header__button button'>OPEN TELEGRAM</a>
      </header>
      <div className='app__box'>
        <h1 className='app__box__title'>OmiAI BOT</h1>
        <div className='app__box__text'>Fully free text and image generator powered by <a href='https://openai.com/blog/chatgpt/' className='app__box__text__link'>ChatGPT</a> and <a href='https://openai.com/dall-e-2/' className='app__box__text__link'>DALL-E</a></div>
        <a href='https://t.me/OmiAI_Bot' className='app__box__button button'>START BOT</a>
      </div>
      <footer className='app__footer'>
        <span className='app__footer__text'>© OMIKAMI 2023. All rights reserved</span>
        <a href='https://heavens.pro/' className='app__footer__heavens'>Designed and developed by Heavens.pro</a>
      </footer>
      <img src={dog} alt='dog' className='app__img' />
      <img src={background} alt='bg' className="app__background" />
      <Preloader />
    </div>
  );
}

export default App;
